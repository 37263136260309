





































import {
  LoginResponseModel,
  RefreshtokenRequestModel,
} from "@/models/interface/auth.interface";
import { authService } from "@/services/auth.service";
import store from "@/store";
import Vue from "vue";

export default Vue.extend({
  name: "Dashboard",
  components: {
    UnitReceiptMonitoringWidget: () =>
      import(
        "@/components/UnitReceiptMonitoring/UnitReceiptMonitoringWidget.vue"
      ),
    ArAgingReminder: () =>
      import("@/components/ArAgingReminder/ArAgingReminder.vue"),
    ContractReminder: () =>
      import("@/components/Dashboard/ContractReminder.vue"),
    SalesOrderTracking: () => import("./SalesOrderTracking.vue"),
    "invoice-overdue": () =>
      import("@/components/Dashboard/InvoiceOverdue.vue"),
    "tax-invoice-sn-alert": () => import("./TaxInvoiceSerialNumberAlert.vue"),
  },

  methods: {
    buttonrefreshToken(): void {
      const payload = {
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token: store.state.refresh_token,
      } as RefreshtokenRequestModel;
      authService
        .refreshToken(payload)
        .then((res: LoginResponseModel) => {
          this.$store.dispatch("ACTIONS_REFRESH_TOKEN", res);
        })
        .catch();
    },
  },
});
