// Core
import { AxiosError } from "axios";

// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import {
  ForgotRequestModel,
  LoginRequestModel,
  LoginResponseModel,
  RefreshtokenRequestModel,
} from "@/models/interface/auth.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import querystring from "querystring";
import { HttpClient } from "./HttpClient.service";

type PayloadStringify<T> = T & Record<string, any>;

export class AuthService extends HttpClient {
  public constructor() {
    super();

    this.signin = this.signin.bind(this);
  }

  public signin(payload: LoginRequestModel): Promise<LoginResponseModel> {
    const newPayloadStringify: PayloadStringify<LoginRequestModel> = payload;
    return this.post<LoginResponseModel, string>(
      Api.LOGIN,
      querystring.stringify(newPayloadStringify),
      // payload,
      {
        // noAuthorization: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public refreshToken(
    payload: RefreshtokenRequestModel
  ): Promise<LoginResponseModel> {
    const newPayloadStringify: PayloadStringify<RefreshtokenRequestModel> =
      payload;
    return this.post<LoginResponseModel, string>(
      Api.LOGIN,
      querystring.stringify(newPayloadStringify),
      // newPayloadStringify,
      {
        // noAuthorization: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public forgotPassword(payload: ForgotRequestModel): Promise<boolean> {
    return this.put<boolean, ForgotRequestModel>(Api.ForgotPassword, payload, {
      // noAuthorization: true,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const authService = new AuthService();
