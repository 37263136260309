var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.$can("read", "tisn-reminder")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("tax-invoice-sn-alert", {
                    attrs: { "data-testid": "tisn-reminder" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "contract-reminder")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("ContractReminder", {
                    attrs: { "data-testid": "contract-reminder" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "unit-receipt-monitoring")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("UnitReceiptMonitoringWidget", {
                    attrs: { "data-testid": "unit-receipt-monitoring" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "sales-tracking")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("SalesOrderTracking", {
                    attrs: { "data-testid": "sales-tracking" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "ar-aging-reminder")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("ArAgingReminder", {
                    attrs: { "data-testid": "ar-aging-reminder" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "invoice-overdue")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("invoice-overdue", {
                    attrs: { "data-testid": "invoice-overdue" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }